'use client';

import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import Select, {
  ActionMeta,
  ControlProps,
  GroupBase,
  GroupHeadingProps,
  GroupProps,
  InputProps,
  MenuProps,
  PropsValue,
  SelectInstance,
  SingleValue,
  components,
} from 'react-select';

import { ScreenSize, useScreenSize } from '@/hooks/useScreenSize';
import { NAVBAR_HEIGHT, NAVBAR_HEIGHT_MOBILE } from '@/utils/constants';
import { isIOS } from '@/utils/isIOS';

import { CustomOptionSelect } from './form/CustomSelect/CustomOptionSelect';
import { getCustomSelectTheme } from './form/CustomSelect/theme';
import { CustomOption } from './form/CustomSelect/types';

import clsx from 'clsx';

type SearchFieldProps = {
  className?: string;
  placeholder: string;
  onChange?: (
    newValue: SingleValue<CustomOption>,
    actionMeta?: ActionMeta<CustomOption>
  ) => void;
  onInputChange?: (newValue: string) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  searchValue?: PropsValue<CustomOption>;
  options: (CustomOption | CustomOptionGroup)[];
  autoFocus?: boolean;
  isScrollOnFocus?: boolean;
  showSearchIndicator?: boolean;
  rightIndicator?: React.ReactNode;
  leftIndicator?: React.ReactNode;
  controlClassName?: string;
};

const Menu = ({ children, ...props }: MenuProps<CustomOption>) => {
  return (
    <components.Menu className="p-5 mt-2 bg-white rounded shadow" {...props}>
      {children}
    </components.Menu>
  );
};

const Input = ({ className, ...rest }: InputProps<CustomOption, false>) => {
  return (
    <components.Input
      data-testid="search-locations-input"
      autoComplete="off"
      className={clsx(className, 'cursor-text')}
      {...rest}
    />
  );
};

interface CustomOptionGroup extends GroupBase<CustomOption> {
  icon?: string;
}

const GroupHeading = (
  props: GroupHeadingProps<CustomOption, boolean, CustomOptionGroup>
) => {
  const { icon } = props.data;

  return (
    <div
      className={clsx(
        'flex items-center gap-[10px] mb-2 text-sm font-bold tracking-wide uppercase'
      )}
    >
      {icon && (
        <Image
          className="p-[1px]"
          data-testid="group-heading-icon"
          src={icon}
          alt={'icon'}
          aria-hidden="true"
        />
      )}
      <components.GroupHeading {...props} />
    </div>
  );
};

const Group = (props: GroupProps<CustomOption, false>) => (
  <div className="mb-[10px] last:mb-0">
    <components.Group {...props} />
  </div>
);

const Control = ({ children, ...props }: ControlProps<CustomOption, false>) => {
  const { leftIndicator, rightIndicator, controlClassName } = props.selectProps;

  return (
    <components.Control
      className={clsx(
        'w-full bg-white rounded-full focus:outline-none',
        controlClassName
      )}
      {...props}
    >
      {leftIndicator}
      {children}
      {rightIndicator}
    </components.Control>
  );
};

export function SearchField({
  placeholder,
  className,
  onChange,
  onInputChange,
  options,
  searchValue,
  autoFocus,
  isScrollOnFocus,
  rightIndicator,
  leftIndicator,
  controlClassName,
}: SearchFieldProps) {
  const screenSize = useScreenSize();
  const [shouldRenderValue, setShouldRenderValue] = useState(
    searchValue != null
  );
  const selectRef =
    useRef<SelectInstance<CustomOption, false, GroupBase<CustomOption>>>(null);

  useEffect(() => {
    if (selectRef.current && autoFocus) {
      selectRef.current.focus();
    }
  }, [autoFocus]);

  const handleInputFocused = (event: React.FocusEvent<HTMLInputElement>) => {
    if (isScrollOnFocus) {
      scrollToFieldAtTop(event.target);
    }
  };

  return (
    <Select
      ref={selectRef}
      name="search-locations-input"
      instanceId="search-locations-input"
      aria-label={placeholder}
      placeholder={placeholder}
      options={options}
      unstyled
      isMulti={false}
      theme={getCustomSelectTheme}
      classNames={{
        container: () => clsx('relative flex text-left', className),
        placeholder: () => 'text-grey-25 truncate',
      }}
      components={{
        Control,
        DropdownIndicator: () => null,
        Option: CustomOptionSelect,
        Menu,
        Input,
        Group,
        GroupHeading,
      }}
      onInputChange={onInputChange}
      onChange={onChange}
      filterOption={() => true}
      noOptionsMessage={() => null}
      value={searchValue}
      onMenuOpen={() => {
        setShouldRenderValue(false);
      }}
      onMenuClose={() => {
        setShouldRenderValue(true);
      }}
      controlShouldRenderValue={shouldRenderValue}
      autoFocus={autoFocus}
      maxMenuHeight={screenSize >= ScreenSize.LG ? 400 : 300}
      onFocus={handleInputFocused}
      leftIndicator={leftIndicator}
      rightIndicator={rightIndicator}
      controlClassName={controlClassName}
    />
  );
}

function scrollToFieldAtTop(element: HTMLInputElement) {
  const { top } = element.getBoundingClientRect();
  const topFromWindow = window.scrollY + top;
  let btnLocation = topFromWindow - 30;
  const navbarHeight =
    window.innerWidth <= ScreenSize.SM ? NAVBAR_HEIGHT_MOBILE : NAVBAR_HEIGHT;
  if (!isIOS()) {
    btnLocation -= navbarHeight;
  }

  setTimeout(
    () =>
      window.scrollTo({
        left: 0,
        top: btnLocation,
        behavior: 'smooth',
      }),
    100
  );
}
